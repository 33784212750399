import firebase from "firebase/app";
import config from "./utils/config";
require("firebase/auth");
require("firebase/firestore");

const firebaseConfig = {
    apiKey: "AIzaSyDz1R-fxD1WA-KLiquraoyjbHKNHVgvImw",
    authDomain: "quizler-65fc1.firebaseapp.com",
    projectId: "quizler-65fc1",
    storageBucket: "quizler-65fc1.appspot.com",
    messagingSenderId: "343193420182",
    appId: "1:343193420182:web:627ada9133189feef85db7",
    measurementId: "G-G92QFQ36TL"
};

// eslint-disable-next-line
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

const db = firebase.firestore();

const googleProvider = new firebase.auth.GoogleAuthProvider();

const facebookprovider = new firebase.auth.FacebookAuthProvider();

export { auth, googleProvider, facebookprovider, firebase, db };
