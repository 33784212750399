var config = {
  // Set the Demo Version
  demo: false,

  //SEO Configurations
  appName: "Quizller",
  metaDescription: "Quizller Quiz is a Web Quiz Application",
  metaKeyWords: "Quizller Quiz,Quiz,Questions,Answers,Online Quiz",

  //API Configurations
  // https://admin.radiologyqb.com/api/
  // https://api.quizller.com/api/
  apiAccessKey: 8525,
  apiUrl: "https://admin.radiologyqb.com/api/",

  //Language Configurations
  // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
  supportedLanguages: ["en", "hi", "ur"],
  defaultLanguage: "en",

  // If your Default Language is not in supportedLanguages then add there first and after that set the defaultLanguage.

  //Quiz Configurations
  deductReviewAnswerCoins: 10, // 10 coins will be deducted if user Review the Answer
  deductIncorrectAnswerScore: 1, // This will deduct the points if user will give wrong answer
  deductLifeLineCoins: 1, // Deduct Coins when using Life Line

  // default country selection Configurations
  DefaultCountrySelectedInMobile: "in", //Default Country Selected in Mobile Login Screen

  // guess the word Quiz Configurations
  Guessthewordhintcoin: 5, //deduct coins guess the word

  // 1 vs 1 battle Quiz Configurations
  battlecorrectanswer: 4,
  randomBattleSeconds:30, // 1 vs 1 battle timer seconds
  Randombattlecoins: 5, //deduct coins 1 vs 1 battle
  randomBattleoneToTwoSeconds: 2, // quick answer :- 1 vs 1 battle points 2 added on first 1 & 2 seconds
  randomBattlethreeToFourSeconds: 1, // quick answer :- 1 vs 1 battle points 1 added on first 3 & 4 seconds

  //Firebase Configurations
  apiKey: "AIzaSyDz1R-fxD1WA-KLiquraoyjbHKNHVgvImw",
  authDomain: "quizler-65fc1.firebaseapp.com",
  projectId: "quizler-65fc1",
  storageBucket: "quizler-65fc1.appspot.com",
  messagingSenderId: "343193420182",
  appId: "1:343193420182:web:627ada9133189feef85db7",
  measurementId: "G-G92QFQ36TL",

  //footer area
  companytext: "Quizller",
  addresstext: "230 Abdel Salam Aref, Loran, Alexandria",
  phonenumber: "+20 1005178303",
  email: "marketopiateam@gmail.com",
  facebooklink: "#",
  instagramlink: "#",
  linkedinlink: "#",
  weblink: "https://marketopiateam.com/",
  companyname: "Marketopia",
};

export default config;
